<template>
  <div class="center-container">
    <div class="flex just-between" @click="changePhone">
      <div>修改注册手机号</div>
      <div class="flex align-center c-8E929B">
        <span> {{ maskMobile }} </span> <van-icon name="arrow" />
      </div>
    </div>
    <van-divider />
    <div class="flex just-between" @click="changeCard">
      <div>修改银行卡号</div>
      <van-icon name="arrow" class="c-8E929B" />
    </div>
    <van-divider />
    <div class="flex just-between" @click="changeIdInfo">
      <div>更新身份证信息</div>
      <van-icon class="c-8E929B" name="arrow" />
    </div>
    <van-divider />
    <div class="flex just-between" @click="checkCompony">
      <div>企业信息</div>
      <van-icon class="c-8E929B" name="arrow" />
    </div>
    <van-divider />
    <van-dialog
      v-model="dialogFlag"
      title="提示"
      confirmButtonColor="#1989fa"
      confirmButtonText="知道了"
      class="confirm-dialog"
      @confirm="dialogFlag = false"
    >
      <div class="tips-text">{{ tips }}</div>
    </van-dialog>
    <div class="login-out">
      <van-button type="info" round size="large" @click="handleLoginOut">退出登录</van-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Divider, Icon, Button } from 'vant'
import { getUserInfo } from '@/apis/userCenter/index'
Vue.use(Divider).use(Icon).use(Button)
export default {
  data() {
    return {
      userBankCardList: null,
      userEnterInfoList: null,
      dialogFlag: false,
      realNameCheck: false,
      maskMobile: '',
      tips: '',
      realPhone: '',
      loading: true,
    }
  },
  created() {
    getUserInfo()
      .then((res) => {
        const {
          data: { userCardInfoList, realNameCheck, userEnterInfoList, maskMobile, realPhone },
        } = res
        this.userBankCardList = userCardInfoList
        this.userEnterInfoList = userEnterInfoList
        this.realNameCheck = realNameCheck
        this.maskMobile = maskMobile
        this.realPhone = realPhone
        this.loading = false
      })
      .catch((err) => {
        console.log(err)
      })
  },
  methods: {
    changePhone() {
      // this.$router.push('/verify')
      this.$router.push({ path: '/verify', query: { phone: this.realPhone } })
    },
    changeCard() {
      if (this.loading) {
        this.dialogFlag = true
        this.tips = '数据请求中,请稍后...'
      } else {
        if (this.userBankCardList.length === 0) {
          this.dialogFlag = true
          this.tips = '您还未绑卡，请先进行绑卡。'
        } else {
          this.$router.push('/changeBankCard')
        }
      }
    },
    changeIdInfo() {
      if (this.realNameCheck) {
        this.$router.push({ path: '/upload-idCard', query: { type: 'update' } })
      } else {
        this.dialogFlag = true
        this.tips = '您还未在本平台进行实名认证，请实名认证后操作。'
      }
    },
    checkCompony() {
      if (this.loading) {
        this.dialogFlag = true
        this.tips = '数据请求中,请稍后...'
      } else {
        if (this.userEnterInfoList.length === 0) {
          this.dialogFlag = true
          this.tips = '您还未添加企业信息，请先操作添加。'
        } else {
          this.$router.push('/componyInfo')
        }
      }
    },
    handleLoginOut() {
      localStorage.removeItem('Token')
      this.$router.replace(`/phoneLogin?appId=${this.$store.state.common.appId}`)
    },
  },
}
</script>

<style lang="less" scoped>
.center-container {
  margin: 0.28rem /* 14/50 */ 0.4rem /* 20/50 */;
  line-height: 0.42rem /* 21/50 */;
  font-size: 0.3rem /* 15/50 */;
  .tips-text {
    padding: 0.6rem;
    color: #333;
    font-weight: bold;
    text-align: center;
  }
  .login-out {
    width: 70%;
    position: fixed;
    bottom: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
