import { render, staticRenderFns } from "./userCenter.vue?vue&type=template&id=02bb6992&scoped=true"
import script from "./userCenter.vue?vue&type=script&lang=js"
export * from "./userCenter.vue?vue&type=script&lang=js"
import style0 from "./userCenter.vue?vue&type=style&index=0&id=02bb6992&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02bb6992",
  null
  
)

export default component.exports